import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import axios, { api } from 'src/services/axios';
import { Container, SubContainer, PreviewContainer, Btn } from './styled';
import { useHistory } from 'react-router-dom';
import { Theme } from 'src/styles/Theme';

interface Item {
  id: string;
  active: boolean;
  createdAt: string;
  title: string;
  prev_legend: string;
  full_legend: string;
  video_url: string;
  thumbnail_url: string;
  image_url: string;
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const CarouselCompoponent = ({ close }: any): React.ReactElement => {
  //const image = localStorage.getItem('image_url');
  const [latestNews, setLatestNews] = useState<any>([]);
  const history = useHistory();
  const [latestNewsWithComments, setLatestNewsWithComments] = useState<any>([]);
  const [showMoreLatestNews, setShowMoreLatestNews] = useState<any>(true);

  async function fetchCommentsForNews() {
    const newsWithComments = await Promise.all(
      latestNews.map(async (newsItem: any) => {
        const commentsResponse = await api.get(
          `/api/v1/comments-latest-news/find/${newsItem.id}/most-liked`
        );
        return { ...newsItem, comments: commentsResponse.data };
      })
    );
    setLatestNewsWithComments(newsWithComments);
  }

  useEffect(() => {
    fetchCommentsForNews();
  }, [latestNews]);

  const isDateValid = (date: any) => {
    if (!date) {
      return true;
    }
    const today = new Date();
    return date <= today.toISOString();
  };
  function getLatestNews() {
    axios.get('/api/v1/latest-news').then((res) => {
      const filterLatestNews = res.data.filter(
        (image: any) => image.active === true && isDateValid(image.publish_date)
      );

      setLatestNews(filterLatestNews);
    });
  }
  const sortItemsByActiveAndDate = (items: Item[]): Item[] => {
    return items.sort((a, b) => {
      if (a.active === b.active) {
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      }
      return a.active ? -1 : 1;
    });
  };
  function handleLatestNewsFilter() {
    if (showMoreLatestNews) {
      return sortItemsByActiveAndDate(latestNewsWithComments).slice(0, 6);
    } else {
      return sortItemsByActiveAndDate(latestNewsWithComments);
    }
  }

  useEffect(() => {
    getLatestNews();
    return () => {
      const videos = document.querySelectorAll('video');
      videos.forEach((video) => {
        video.play();
      });
    };
  }, []);
  return (
    <>
      {latestNewsWithComments.length > 0 && (
        <Carousel
          onChange={(index, item) => console.log(index, item)}
          showThumbs={false}
          autoPlay={true}
          infiniteLoop={true}
          interval={3000}
        >
          {latestNewsWithComments &&
            handleLatestNewsFilter().map((image: any, index: number) => {
              if (image.active === false) {
                return <></>;
              }
              return (
                <Container key={index}>
                  {image.image_url ? (
                    <img alt='' src={image.image_url} />
                  ) : (
                    <video
                      src={image.video_url}
                      poster={image?.thumbnail_url}
                      controls={true}
                    ></video>
                  )}
                  <div className='text'>
                    <p>{image.prev_legend}</p>
                    <button
                      onClick={() =>
                        history.push(`/announcements?id=${image.id}`)
                      }
                    >
                      Read More
                    </button>
                  </div>
                </Container>
              );
            })}
        </Carousel>
      )}
      <PreviewContainer>
        <h1>Previous Articles</h1>
        <button onClick={() => close()}>Submit Latest News Suggestion</button>
      </PreviewContainer>
      <SubContainer>
        {latestNews &&
          handleLatestNewsFilter().map((image: any) => {
            if (image.active === false) {
              return <></>;
            }
            return (
              <div className='space' key={Math.random()}>
                {image.image_url ? (
                  <img alt='' src={image.image_url} />
                ) : image.thumbnail_url ? (
                  <img alt='' src={image.thumbnail_url} />
                ) : (
                  <video
                    style={{ width: 80 }}
                    src={image.video_url}
                    poster={image?.thumbnail_url}
                    loop
                    controls={true}
                  ></video>
                )}
                <div
                  className='legend'
                  style={{ cursor: 'pointer', zIndex: 9999 }}
                  onClick={() => history.push(`/announcements?id=${image.id}`)}
                >
                  <p>{image.prev_legend}</p>
                  <a>Read More</a>
                  {(image.comments?.length ?? 0) > 0 && (
                    <p style={{ color: Theme.primary, fontWeight: 'bold' }}>
                      {image.comments?.length}{' '}
                      {image.comments?.length === 1 ? 'comment' : 'comments'}
                    </p>
                  )}
                </div>
              </div>
            );
          })}
      </SubContainer>
      {
        <Btn
          onClick={() => {
            history.push('/announcements');
          }}
        >
          Show More
        </Btn>
      }
    </>
  );
};
