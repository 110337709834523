import {
  Button,
  Checkbox,
  FormControlLabel,
  FormLabel,
  TextField
} from '@material-ui/core';
import React from 'react';
import { useState } from 'react';
import * as S from './styled';
import api from '../../services/axios';
import Select from 'react-select';

export default function NotifyDashboard(): React.ReactElement {
  const [form, setForm] = useState({
    title: '',
    legend: '',
    url: '',
    locations: false,
    group: false,
    every: false
  });
  const [formDesktop, setFormDesktop] = useState({
    content: '',
    url: '',
    locations: false,
    group: false,
    every: false
  });

  const [locationsInfo, setLocationsInfo] = useState<any>([]);
  const [rolesInfo, setRolesInfo] = useState<any>([]);
  const [group, setGroup] = useState<any>([]);
  const [profile, setProfile] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [loadingDesktop, setLoadingDesktop] = useState(false);
  const [groupDesktop, setGroupDesktop] = useState<any>([]);
  const [profileDesktop, setProfileDesktop] = useState<any>([]);

  const fetchLocations = async () => {
    const data = await api.get('/api/v1/contacts/locations');
    setLocationsInfo(data.data);
  };

  const fetchGroups = async () => {
    const data = await api.get('/api/v1/contacts/roles');
    setRolesInfo(data.data);
  };
  const handleSelectGroup = (event: any) => {
    setGroup(event);
  };

  const handleSelectProfile = (event: any) => {
    setProfile(event);
  };

  const handleSelectGroupDesktop = (event: any) => {
    setGroupDesktop(event);
  };

  const handleSelectProfileDesktop = (event: any) => {
    setProfileDesktop(event);
  };

  const locations = (
    <div>
      <Select
        isMulti
        name='colors'
        options={locationsInfo}
        className='basic-multi-select'
        classNamePrefix='Locations '
        placeholder='Select Locations'
        onChange={handleSelectProfile}
      />
    </div>
  );
  const groups = (
    <div>
      <Select
        isMulti
        name='colors'
        options={rolesInfo}
        placeholder='Select Groups'
        className='basic-multi-select'
        classNamePrefix='Groups'
        onChange={handleSelectGroup}
      />
    </div>
  );

  const locationsDesktop = (
    <div>
      <Select
        isMulti
        name='colors'
        options={locationsInfo}
        className='basic-multi-select'
        classNamePrefix='Locations '
        placeholder='Select Locations'
        onChange={handleSelectProfileDesktop}
      />
    </div>
  );
  const groupsDesktop = (
    <div>
      <Select
        isMulti
        name='colors'
        options={rolesInfo}
        placeholder='Select Groups'
        className='basic-multi-select'
        classNamePrefix='Groups'
        onChange={handleSelectGroupDesktop}
      />
    </div>
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    api
      .post(
        '/api/v1/notify/mobile-notify',
        {
          title: form.title,
          legend: form.legend,
          url: form.url,
          locations: profile,
          groups: group,
          every: form.every
        },
        {
          timeout: 0
        }
      )
      .then(() => {
        alert('Notification Sent');
        setLoading(false);
        setForm({
          title: '',
          legend: '',
          url: '',
          locations: false,
          group: false,
          every: false
        });
        setProfile([]);
        setGroup([]);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleSubmitDesktop = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoadingDesktop(true);
    api
      .post(
        '/api/v1/notify/desktop-notify',
        {
          content: formDesktop.content,
          url: formDesktop.url,
          locations: profileDesktop,
          groups: groupDesktop,
          every: formDesktop.every
        },
        {
          timeout: 0
        }
      )
      .then(() => {
        alert('Notification Sent');
        setLoadingDesktop(false);
        setFormDesktop({
          content: '',
          url: '',
          locations: false,
          group: false,
          every: false
        });
        setProfileDesktop([]);
        setGroupDesktop([]);
      })
      .catch(() => {
        setLoadingDesktop(false);
      });
  };

  const routeOptions = [
    {
      value: 'Shortcuts',
      label: 'FEG Apps'
    },
    {
      value: 'company',
      label: 'Announcements'
    },
    {
      value: 'Trivia',
      label: 'Trivia'
    },
    {
      value: 'Ask FEG',
      label: 'Ask FEG'
    },
    {
      value: 'Home',
      label: 'Home'
    }
  ];

  return (
    <S.Container>
      <S.Divisor>
        <S.Form onSubmit={handleSubmit}>
          <h1>Mobile Notification</h1>
          <TextField
            id='title'
            label='Title'
            variant='outlined'
            value={form.title}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setForm({ ...form, title: e.target.value })
            }
          />
          <TextField
            id='legend'
            label='Text'
            variant='outlined'
            value={form.legend}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setForm({ ...form, legend: e.target.value })
            }
          />
          <FormLabel>
            <h3>Redirect User To:</h3>
          </FormLabel>
          <Select
            name='Url'
            options={routeOptions}
            className='basic-multi-select'
            classNamePrefix='select'
            onChange={(e) => {
              if (e) {
                setForm({ ...form, url: e.value });
              }
            }}
          />

          <div>
            <FormControlLabel
              control={<Checkbox color='primary' checked={form.every} />}
              label='Everyone'
              color='primary'
              onChange={() => {
                setForm({ ...form, every: !form.every });
              }}
              value={form.every}
            />

            <>
              <FormControlLabel
                control={<Checkbox color='primary' checked={form.group} />}
                label='Groups'
                color='primary'
                onClick={fetchGroups}
                onChange={() => setForm({ ...form, group: !form.group })}
                value={form.group}
              />
              <FormControlLabel
                control={<Checkbox color='primary' checked={form.locations} />}
                label='Locations'
                color='primary'
                onClick={fetchLocations}
                onChange={() =>
                  setForm({ ...form, locations: !form.locations })
                }
                value={form.locations}
              />
            </>
          </div>

          <>
            {form.locations && locations}

            {form.group && groups}
          </>

          <Button
            variant='contained'
            type='submit'
            color='primary'
            disabled={loading}
          >
            {loadingDesktop ? 'Sending...' : 'Send'}
          </Button>
        </S.Form>
        <div className='border'>.</div>
        <S.Form onSubmit={handleSubmitDesktop}>
          <h1>Desktop Notification</h1>
          <TextField
            id='content'
            label='Content'
            variant='outlined'
            value={formDesktop.content}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFormDesktop({ ...formDesktop, content: e.target.value })
            }
          />

          <FormLabel>
            <h3>Redirect User To:</h3>
          </FormLabel>
          <Select
            name='Url'
            options={routeOptions}
            className='basic-multi-select'
            classNamePrefix='select'
            onChange={(e) => {
              if (e) {
                setFormDesktop({ ...formDesktop, url: e.value });
              }
            }}
          />

          <div>
            <FormControlLabel
              control={<Checkbox color='primary' checked={formDesktop.every} />}
              label='Everyone'
              color='primary'
              onChange={() => {
                setFormDesktop({ ...formDesktop, every: !formDesktop.every });
              }}
              value={formDesktop.every}
            />

            <>
              <FormControlLabel
                control={
                  <Checkbox color='primary' checked={formDesktop.group} />
                }
                label='Groups'
                color='primary'
                onClick={fetchGroups}
                onChange={() =>
                  setFormDesktop({
                    ...formDesktop,
                    group: !formDesktop.group
                  })
                }
                value={formDesktop.group}
              />
              <FormControlLabel
                control={
                  <Checkbox color='primary' checked={formDesktop.locations} />
                }
                label='Locations'
                color='primary'
                onClick={fetchLocations}
                onChange={() =>
                  setFormDesktop({
                    ...formDesktop,
                    locations: !formDesktop.locations
                  })
                }
                value={formDesktop.locations}
              />
            </>
          </div>

          {formDesktop.locations && locationsDesktop}

          {formDesktop.group && groupsDesktop}

          <Button
            variant='contained'
            type='submit'
            color='primary'
            disabled={loadingDesktop}
          >
            {loadingDesktop ? 'Sending...' : 'Send'}
          </Button>
        </S.Form>
      </S.Divisor>
    </S.Container>
  );
}
